html, body {
  /* Disable pull to refresh on Safari */
  overscroll-behavior-y: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Prevent chrome from popping navigation history on swipe */
  overscroll-behavior-x: none;
}

@font-face {
  font-family: "Roboto";
  src: url("../public/fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: bold;
  src: url("../public/fonts/Roboto-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: 100;
  src: url("../public/fonts/Roboto-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: 300;
  src: url("../public/fonts/Roboto-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: 500;
  src: url("../public/fonts/Roboto-Medium.ttf") format("truetype");
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

a {
  color: inherit;
}

*::selection {
  background-color: #ffa800;
  color: #fff;
}

*::-moz-selection {
  background-color: #ffa800;
  color: #fff;
}

.arrow-btn {
  display: flex;
  border: none;
  background: none;
  cursor: pointer;
  border-radius: 100%;
  padding: 4px;
}

.arrow-btn:hover {
  background: rgb(200, 200, 200);
}

.arrow-btn:disabled {
  cursor: default;
  background: none;
}

blockquote {
  margin-left: 1rem;
}